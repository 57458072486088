<template>
    <div class="enrollement-form">
        <b-form class="custom-fuel-form">
            <h4>{{$t('accountInformations')}}</h4>
            <b-col cols="6" class="custom-form-group">
                <label for="firstname">{{$t('firstname')}} * :</label>
                <b-form-input id="firstname" ref="firstname" state="null" class="custom-input" v-model="form.firstName"
                    type="text" required/>
            </b-col>

            <b-col cols="6" class="custom-form-group">
                <label for="lastname">{{$t('lastname')}} * :</label>
                <b-form-input id="lastname" ref="lastname" state="null" class="custom-input" v-model="form.lastName"
                    type="text" />
            </b-col>

            <b-col cols="12" class="custom-form-group">
                <label for="email">{{$t('email')}} * :</label>
                <b-form-input id="email" ref="email" state="null" class="custom-input" v-model="form.email"
                    type="email" />
            </b-col>

            <b-col cols="12" class="custom-form-group">
                <label for="address">{{$t('address')}} :</label>
                <b-form-input id="address" ref="address" state="null" class="custom-input" v-model="form.address"
                    type="text" />
            </b-col>

            <b-col cols="6" class="custom-form-group">
                <label for="city">{{$t('city')}} :</label>
                <b-form-input id="city" ref="city" state="null" class="custom-input" v-model="form.city" type="text" />
            </b-col>

            <b-col cols="6" class="custom-form-group">
                <label for="country">{{$t('country')}} :</label>
                <b-form-input id="country" ref="country" state="null" class="custom-input" v-model="form.country"
                    type="text" />
            </b-col>
            <div class="form-footer">
                <b-button type="submit" class="btn-active" variant="primary" @click="save">{{$t('save')}}</b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
    var Customer = require("@/assets/js/Customer");
    var Stats = require("@/assets/js/Stats");

    export default {
        name: 'EditAccount',
        data() {
            return {
                form: {
                    token: localStorage.getItem("DKC_fuel_token") || null,
                    firstName: "",
                    lastName: "",
                    email: "",
                    cp: "",
                    address: "",
                    city: "",
                    country: "",
                    loyaltyCardNumber: null,
                    allergens: null
                }
            }
        },
        mounted(){
           
            Stats.setStats({ nbProfileAccess : 1 })
            Customer.getCustomer(customer => {
                customer = JSON.parse(customer);
                this.form.firstName = customer.firstName;
                this.form.lastName = customer.lastName;
                this.form.email = customer.email;
                this.form.cp = customer.address.cp;
                this.form.address = customer.address.address;
                this.form.city = customer.address.city;
                this.form.country = customer.address.country;
                this.form.firstName = customer.firstName;
            })
            
            
        },
        methods: {
            save(e) {
                e.preventDefault();
                if(this.form.firstName != "" && this.form.lastName != "" && this.form.email != ""){
                    Customer.updateCustomer(this.form);
                    localStorage.setItem("DKC_firstname", this.form.firstName)
                    localStorage.setItem("DKC_lastname", this.form.lastName)
                    localStorage.setItem("DKC_email", this.form.email)
                }else{
                    this.$parent.showAlert(this.$t("errorUpdateCustomer"), "error")
                }
                

            }
        }
    }
</script>