import {getConfig, isNotNull} from "./Utils";
import {setStats} from "./Stats";

var app = getConfig();

export function registerCustomer(firstname, lastname, callback = null){
    var path = "/customer/register";
    var url = app.DKW_URL + path;

    var request = new XMLHttpRequest();
    var params = "firstName=" + firstname + "&lastName=" + lastname;
    var token = null;
    var univers = localStorage.getItem("DKC_univers");
    switch(univers){
        case "1":
            token = localStorage.getItem("DKC_fuel_token");
            break;
        case "2":
        case "4":
        case "5":
        case "6":
            token = localStorage.getItem("DKC_food_token");
            break;
        case "3":
            token = localStorage.getItem("DKC_fashion_token");
            break;
    }
    if(token != null){
        params += "&token=" + token;
    }
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = JSON.parse(request.response);
                if (response.error == false) {
                    switch(univers){
                        case "1":
                            localStorage.setItem("DKC_fashion_token", response.customerToken );
                            break;
                        case "2":      
                        case "4":
                        case "5":
                        case "6":
                            localStorage.setItem("DKC_food_token", response.customerToken );
                            break;
                        case "3":
                            localStorage.setItem("DKC_fuel_token", response.customerToken );
                            break;
                    }
                    localStorage.setItem("DKC_firstname", response.firstName);
                    localStorage.setItem("DKC_lastname", response.lastName);
                    localStorage.setItem("DKC_complexId", response.complexId);
                    localStorage.setItem("DKC_needPublishPoster", "true");

                    var complexIdParent = localStorage.getItem("DKC_complexIdParent");
                    var parameters = {
                        complexIdParent: localStorage.getItem('DKC_complexIdParent'),
                    }
                    if(isNotNull(complexIdParent)){
                        setStats(parameters);
                    }
                    if(isNotNull(callback)){
                        callback()
                    }
                }else{
                    app.appContext.showAlert(app.appContext.$t('errorRegisterCustomer'),"error");
                }
            }else{
                app.appContext.showAlert(app.appContext.$t('errorRegisterCustomer'),"error");
            }
        }
    };
    if(callback != null && (univers == "2" || univers == "4" || univers == "5" || univers == "6")){
        request.open('POST', url);
    }else{
        request.open('POST', url, false);
    }
    request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    request.send(params);
}

export function updateCustomer(parameters){
    var path = "/customer/update";
    var url = app.DKW_URL + path;
    var error = true;

    var request = new XMLHttpRequest();
    var params = "params=" + JSON.stringify(parameters)
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = JSON.parse(request.response);
                if (response.error == true) {
                    app.appContext.showAlert(app.appContext.$t('errorUpdateCustomer'),"error");
                }else{
                    app.appContext.showAlert(app.appContext.$t('successUpdateCustomer'),"success");
                }
            }
        }
    };
    request.open('POST', url, false);
    request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    request.send(params);

    return error;
}

export function updateCustomerEmail(email, callback){
    var path = "/customer/update/email";
    var url = app.DKW_URL + path;
    var error = false;

    var token = "";
    var univers = localStorage.getItem("DKC_univers");
    switch(univers){
        case "1":
            token = localStorage.getItem("DKC_fashion_token");
            break;
        case "2":
        case "4":
        case "5":
        case "6":
            token = localStorage.getItem("DKC_food_token");
            break;
        case "3":
            token = localStorage.getItem("DKC_fuel_token");
            break;
    }

    var request = new XMLHttpRequest();
    var params = "token=" + token + "&email=" + email;
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = JSON.parse(request.response);
                if (response.error == true) {
                    error = true;
                    app.appContext.showAlert(app.appContext.$t('errorUpdateEmail'),"error");
                }
                callback(error)
            }
        }
    };
    request.open('POST', url, false);
    request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    request.send(params);
}


export function updateCustomerLoyaltyCard(loyaltyCardNumber){
    var path = "/customer/update/loyalty/card";
    var url = app.DKW_URL + path;
    var token = "";
    var univers = localStorage.getItem("DKC_univers");
    switch(univers){
        case "1":
            token = localStorage.getItem("DKC_fashion_token");
            break;
        case "2":
        case "4":
        case "5":
        case "6":
            token = localStorage.getItem("DKC_food_token");
            break;
        case "3":
            token = localStorage.getItem("DKC_fuel_token");
            break;
    }

    var request = new XMLHttpRequest();
    var params = "token=" + token + "&cardNumber=" + loyaltyCardNumber;
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = JSON.parse(request.response);
                if (response.error == true) {
                    app.appContext.showAlert(app.appContext.$t('errorUpdateLoyaltyCard'),"error");
                }
            }
        }
    };
    request.open('POST', url, false);
    request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    request.send(params);
}


export function updateCustomerLoyaltyPoints(newNbPoints){
    var path = "/customer/update/loyalty/points";
    var url = app.DKW_URL + path;
    var token = "";
    var univers = localStorage.getItem("DKC_univers");
    switch(univers){
        case "1":
            token = localStorage.getItem("DKC_fashion_token");
            break;
        case "2":
        case "4":
        case "5":
        case "6":
            token = localStorage.getItem("DKC_food_token");
            break;
        case "3":
            token = localStorage.getItem("DKC_fuel_token");
            break;
    }

    var request = new XMLHttpRequest();
    var params = "token=" + token + "&nbPoints=" + newNbPoints;
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = JSON.parse(request.response);
                if (response.error == true) {
                    app.appContext.showAlert(app.appContext.$t('errorUpdateLoyaltyPoints'),"error");
                }
            }
        }
    };
    request.open('POST', url, false);
    request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    request.send(params);
}

export function getCustomer(callback){
    var path = "/customer/getcustomer/token";
    var url = app.DKW_URL + path;
    var token = "";
    var univers = localStorage.getItem("DKC_univers");
    switch(univers){
        case "1":
            token = localStorage.getItem("DKC_fashion_token");
            break;
        case "2":
        case "4":
        case "5":
        case "6":
            token = localStorage.getItem("DKC_food_token");
            break;
        case "3":
            token = localStorage.getItem("DKC_fuel_token");
            break;
    }

    var request = new XMLHttpRequest();
    var params = "?token=" + token;
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200 && request.response != "") {
                callback(request.response);
            }else{
                app.appContext.showAlert(app.appContext.$t('errorGetCustomer'),"error");
            }
        }
    };
    request.open('GET', url + params, false);
    request.send();
}